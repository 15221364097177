.spinner-holder {
    /* overflow: hidden; */
    /* position:relative; */
    /* width: 30%; */
    /* left: 0; */
    
  }
  
  .sp-heart {
    /* top: 20px; */
    top:0;
  }
  
  .spinner-heart {
    width: 10px;
    height: 20px;
    margin: 5px auto;
    border-radius: 100%;  
    animation: pulse 1.0s infinite ease-in-out;
  }
  
  .heart {
    fill: #FE4365;
  }
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.3); }
    100% { transform: scale(1); }
  }
  
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  .loader {
    /* margin: 0 auto; */
    font-size: 2.5px;
    position: relative;
    /* text-indent: -9999em; */
    border-top: 1.1em solid rgba(254,67,101, 0.2);
    border-right: 1.1em solid rgba(254,67,101, 0.2);
    border-bottom: 1.1em solid rgba(254,67,101, 0.2);
    border-left: 1.1em solid #FE4365;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.0s infinite linear;
    animation: load8 1.0s infinite linear;
    bottom: 19px;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
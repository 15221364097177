.App {
  text-align: center;
  font-family: 'Helvetica Neue', sans-serif;
  /* font-family: "Secular One", sans-serif; */

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevents horizontal scrolling */
  
}

.custom-home-page {
  /* font-family: 'Helvetica Neue', sans-serif; */
  font-family: "Secular One", sans-serif;

  color: #333;
  /* background-color: #7c69e6; */
  /* background-color: #d6b2b2; */
  /* background-color: #58aaed; */
  /* background: linear-gradient(45deg, #ff5858, #e48ca5, #aa00ff); */
  background: linear-gradient(45deg, #aa00ff,#ff5858, #e48ca5 );

  padding: 20px; /* Add padding for content spacing */
  box-sizing: border-box; /* Ensures padding and border are included in width/height */
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
  /* height: 100vh; */
  display: flex; /* Use flexbox to center content vertically and horizontally */
  justify-content: center; /* Center horizontally */
}

.hero {
  padding: 40px;
  min-height: 92.5vh;
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-radius: 0;
  /* overflow: hidden; */
  margin-top: 10px;
  /* background-color: #58aaed; */
}

@media (min-width: 768px) { /* Adjust this breakpoint based on your design needs */
  .hero {
    align-items: center; /* Change align-items to stretch on larger screens */
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: center;
    min-height: 94.5vh;

  }
}
.hero::after {
  content: '';
  position: absolute;
  width: 80px; /* Size of the circle */
  height: 80px; /* Size of the circle */
  background-color: #ffffff30;
  border-radius: 50%;
  top: 10%; /* Starting position */
  left: 10%; /* Starting position */
  animation: grow 1s ease-out forwards; /* This circle grows last */
  animation-delay: 2s;
  opacity: 0;
  z-index: 0;
}
.hero::before {
  content: '';
  position: absolute;
  width: 150px; /* Size of the circle */
  height: 150px; /* Size of the circle */
  background-color: #ffffff30;
  border-radius: 50%;
  top: 15%; /* Starting position */
  left: 25%; /* Starting position */
  animation: grow 1s ease-out forwards; /* This circle grows second */
  animation-delay: 1s;
  opacity: 0;
  z-index: 0;
}
.hero .circle {
  content: '';
  position: absolute;
  width: 40px; /* Size of the circle */
  height: 40px; /* Size of the circle */
  background-color: #ffffff30;
  border-radius: 50%;
  top: 20%;
  left: 5%; /* Starting position */
  animation: grow 1s ease-out forwards;
  opacity: 0;
  z-index: 0;
}


@keyframes grow {
  0% {
    transform: scale(0); /* Starts from no size */
    opacity: 0; /* Completely transparent */
  }
  100% {
    transform: scale(1); /* Grows to the defined size */
    opacity: 1; /* Fully opaque */
  }
}





.cool-move {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 20px; */
  z-index: 5;
  
}

@media (min-width: 768px) { /* Adjust this breakpoint based on your design needs */
  .cool-move  {
    /* position: absolute; */

  }
}

.cool-move h1 {
  /* background-color: #130647; */
  color: white;
  padding: 20px 40px;
  /* border-radius: 15px; */
  text-align: center;
  margin: 20px 0;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.cool-move p {
  color: white;
  font-size: 21px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* font-family: "Secular One", sans-serif; */
  /* font-family: "Secular One", sans-serif; */
  font-style: normal;
  
}

.cool-move input[type="file"] {
  border: 1px solid #ccc;
  background-color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cool-move button {
  /* background-color: #130647; */
  /* color: white; */
  /* padding: 10px 20px; */
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

/* .cool-move button:hover {
  background-color: #0a568c;
  transform: scale(1.05);
} */

.cool-move img {
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 5;

}

.border {
  border: 2px dashed #ccc;
  border-radius: 10px ;
  padding: 10px; /* Further reduced space around the image */
  z-index: 5;

}

.response-container {
  position: relative;
  color: #333;
  background: #f5f5f5;
  padding: 15px;
  width:-webkit-fill-available;
  border-radius: 10px;
  border: 1px solid #ccc;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.menu-icon {
  position: fixed;
  /* top: 20px; */
  right: 20px;
  cursor: pointer;
  font-size: 24px;
  z-index: 1000;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: #130647;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  padding: 60px 20px 20px 20px;
  z-index: 999;
}

.menu a {
  color: white;
  padding: 10px;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s;
}

.menu a:hover {
  color: #f0f0f0;
}

.opener-filter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

@media (min-width: 768px) { /* Adjust this breakpoint based on your design needs */
  .opener-filter {
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: flex-start;
    min-height: 94.5vh;

  }
}

.title-logo-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #173087;
  color: white;
  padding: 5px 5px;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 6;
}

.logo,
.cool-move h1 {
  /* margin: 10px 10px; */
}

.logo {
  width: 50px;
  height: auto;
}











.custom-home-page-login{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.hero-login {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-weight: 700;
height:auto;
/* background-image: url('https://source.unsplash.com/1600x900/?nature'); */
background-size: cover;
background-position: center;
color: #615d5d;
text-shadow: 0 0 0.7rem rgba(0, 0, 0, 0.527);
}

.hero-login h1 {
font-size: 550%;
margin-bottom: 20px;
z-index: 2000;
}

.hero-login p {
font-size: 24px;
margin-bottom: 40px; 
}

.hero-login button {
/* background-color: #5839e40e; */
color: #333;
font-size: 18px;
padding: 12px 24px;
border-radius: 4px;
border: none;
cursor: pointer;
transition: all 0.5s ease-in-out;
}

.hero-login button:hover {
background-color: #333;
color: #fff;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.login-move {
  position: absolute;
  top: 40%;
  right: 50%;
  transform: translateY(-20%);
  opacity: 1;
  animation: fadeIn 2s ease-in-out forwards;
}

.login-move.show {
  left: 50%;
  transition: all 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.login-move h1 {
  color: #584e4e;
  font-size: 6rem;
  font-weight: bold;
  margin: 0;
  margin-top: -10%;
  opacity: 0;
  animation: slideIn 2s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.1s forwards;
}

.login-move.show h1 {
  opacity: 0;
}

.login-move p {
  font-size: 2rem;
  margin: 0;
  opacity:0;
  position: absolute;
  left: 120%;
  top: 0%;
  transform: translateY(-50%);
  animation: slideIn 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.5s forwards;
}

.login-move.show p {
  left: calc(50% + 50px);
  opacity: 0;
}

.login-move.p1 {
  font-size: 2rem;
  margin: 0;
  opacity: 1;
  position: absolute;
  left: 120%;
  top: 60%;
  transform: translateY(-50%);
  animation: slideIn 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.0s forwards;
}

.login-move.show.p1 {
  left: calc(50% + 100px);
  opacity: 0;
}

.login-move .subtitle {
  font-size: 2rem;
  margin: 0;
  opacity: 0;
  position: absolute;
  /* Adjust other styling properties as needed */
}


.sub-login {
  display: flex;
  margin-bottom:0px;
  flex-direction: row; 
  align-items: center;
  justify-content: center;
  margin-top:200px;
  width: 100%;
}
.sub-login .btn-login{
  animation: buttonLeft 0.5s 0.1s forwards,buttonBelow .5s 1.1s forwards;
  opacity:0;
  display: block;
}
.sub-login .btn-signup{
  animation: buttonRight 0.5s 0.1s forwards,buttonUp .5s 1.1s forwards;
  opacity:0;
  display: block;
}
@keyframes buttonLeft{
0% {
    transform: translateX(-1000%) ;
    opacity: 0;
}
100% {
    transform: translateX(0);
    opacity: 1;
}
}
@keyframes buttonBelow{
0% {
    transform: translatey(0%) ;
}
100% {
    transform: translatey(50%);
}
}
@keyframes buttonRight{
0% {
    transform: translateX(1000%);
    opacity: 0;
}
100% {
    transform: translateX(0);
    opacity: 1;
}
}
@keyframes buttonUp{
0% {
    transform: translatey(0%) ;
}
100% {
    transform: translatey(-50%);
}
}
.sub-login button {
  /* background-color: #5839e40e; */
  color: #333;
  font-size: 18px;
  margin-bottom:0px;
  padding: 12px 24px;
  border-radius: 4px;
  border: none;
  /* margin-top: 50%; */
  cursor: pointer;
/*  transition: all 0.5s ease-in-out;*/
}

.sub-login button:hover {
  background-color: #333;
  color: #fff;
}


.copy-button {
  position: absolute !important;
  top: 5px !important;
  right: 5px !important;
  cursor: pointer !important;
  padding: 4px  !important;
  font-size: 10px !important;
  line-height: 1 !important;
  background-color: lightgray !important;
  border: 1px solid darkgray !important;
  border-radius: 5px !important;
  color:white !important;
}


.like-button {
  color: red !important;
  position: absolute !important;
  top: 5px !important;
  left: 5px !important;
  cursor: pointer !important;
  padding: 4px 4px !important;
  font-size: 10px !important;
  line-height: 1 !important;
  background-color: lightgray !important;
  border: 1px solid darkgray !important;
  border-radius: 5px !important;
  margin-bottom: 10px; /* Add margin-bottom to create space below the button */
}



/* #btn {
  position: absolute;
  left: -100%;
  top: -100%;
  opacity: 0;
  z-index: -1;
} */

#btn {
  /* position: absolute; */
  /* left: 5px !important;
  top: 5px!important;
  z-index: -1; */
  opacity: 0;
  
}

.btn-love {
  position: absolute !important;
  /* z-index: -1; */
  left: 5px !important;
  right: 0 !important;
  top: 5px !important;
  bottom: 0;
  margin: auto;
  padding: 4px 4px !important;
  /* height: 100%;
  width: 100%; */
  line-height: 1 !important;

  cursor: pointer;
}
.btn-love:after {
  content: "";
  position: absolute;
  left: -10%;
  bottom: 30%; 
  margin: 0 auto;
  background: url(https://abs.twimg.com/a/1446862637/img/t1/web_heart_animation.png) 0 0 no-repeat;
  background-size: 2900%;
  
  height: 100px;
  width: 100px;
  padding: vw; /* Adjust padding based on viewport width */
  /* margin-bottom: 4vw; */
}

/* Media query for iPad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .btn-love:after {
    left: 5%; /* Adjust the left position for iPad */
    bottom: 10%; /* Adjust the bottom position for iPad */
    transform: translateX(-50%); /* Center the button horizontally */
  }
}

#btn:checked + .btn-love:after {
  -webkit-animation: heart-burst steps(28) 0.8s 1 both;
          animation: heart-burst steps(28) 0.8s 1 both;
}

@-webkit-keyframes heart-burst {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

@keyframes heart-burst {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}


.liked-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

.liked-item:hover {
  background-color: #f1f1f1;
}

.liked-item button {
  background-color: #ff4d4d;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.liked-item button:hover {
  background-color: #e60000;
}

.tips-button {
  position: fixed;
  top: 65px;
  right: 0px;
  padding: 5px;
  margin-right: 10px;
  margin-top: 10px;
  background-color: #6200ea;
  color: white;
  z-index: 2; /* Ensure the button is above other elements */
}
@media (min-width: 768px) { 
  .tips-button{
   
    z-index: 2; 
    margin-right: 20px;

  }
}
/* marginTop:'20px',padding:'5px',borderRadius:'10px',maxWidth:'50%' */
.login_button{
margin-top: 20px;
padding: 5px;
border-radius: 10px;
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3)

}
@media (min-width: 768px) { 
  .login_button{
   
    max-width: 50%;

  }
}



.tips {
  position: fixed;
  direction: rtl;
  top: 60px;
  left: 20px;
  background-color: #775757;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 2000; /* Ensure the tips are above other elements */
  text-align: right; /* Align text to the left */
}


@keyframes flash {
  0% { opacity: 1;
  }
  50% { opacity: 0.2;
  }
  100% { opacity: 1; 
  }
}

.flashing {
  animation: flash 1s infinite;
}

.advanced2{
  /* top: 18px; */
  right: px;
  /* transform: rotate(45deg); */
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #ffe940;
  border-radius: 10px;
  font-size: larger;
}


.checkmark {
  width: 200px;
  margin: 0 auto;
  padding-top: 40px;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  animation: dash 2s ease-in-out;
  -webkit-animation: dash 2s ease-in-out;
}

.spin {
  animation: spin 2s;
  -webkit-animation: spin 2s;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
}



@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.make_image_bigger {
  max-width: 290px;
  max-height: 310px;
}

@media (min-width: 768px) { /* Adjust this breakpoint based on your design needs */
  .make_image_bigger {
    max-width: 590px;
  max-height: 560px;

  }
}


.animatedbutton {
  position: relative;
  overflow: visible;
  border: none;
  background-color: #f0f0f0; /* Replace with your desired background color */
}

.animatedbutton::before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: white;
  z-index: -1;
  border-radius: 10px;
}

.animatedbutton::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: white;
  z-index: -2;
  filter: blur(30px);
  border-radius: 10px;
}

.animatedbutton::before,
.animatedbutton::after {
  background: linear-gradient(90deg, white, transparent, transparent);
  background-size: 200% 100%;
  animation: borderAnimation 3s linear infinite;
}

@keyframes borderAnimation {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}


.like-btn-container {
  position: absolute;
    box-shadow: none;
    top: 0;
    transition: all 0.1s ease-out;
    align-items: center; 
    justify-content: center; 
    /* border-radius: 10px;  */
    border:'none';
    border-radius: none; 
    font-weight: 600;
    display: flex;
    flex-wrap: wrap;
    scale: 1.8;
    margin: 30px 0;
  }
  
  .svg-icon-answer-like-circle {
    position: absolute;
    left: calc(50% - 13px);
    top: calc(50% - 13px);
    z-index: -1;
  }
  
  .like-btn {
    /* background-color: rgba(255, 255, 255, 0.05); */
    height: 0px;
    width: 0px;
    flex: 0 0 28px;
    margin: auto auto 0;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-radius: 20px; */
    cursor: pointer;
    /* font-size: var(--extra-small-regular); */
    font-weight: 600;
    /* border: 1px solid rgba(255, 255, 255, 0.2); */
    transition: all 0.1s ease-out;
    outline: 0;
    position: relative;
    margin-bottom: 40px;

  }
  
  .like-btn svg:not(.svg-icon-answer-like-circle) {
    transition: all 0.1s ease-out;
    scale: 1;
  }
  
  .like-btn:active svg:not(.svg-icon-answer-like-circle) {
    scale: 0.8;
  }
  
  .like-btn svg {
    height: auto;
    width: 16px;
    flex: 0 0 16px;
    width: auto;
    transition: all 0.1s ease-out;
    scale: 1;
    transition: all 0.2s ease-out;
    overflow: visible;
  }
  
  .like-btn span + svg {
    margin-left: 4px;
  }
  
  .like-btn-container.liked .like-btn {
    /* background-color: rgba(255, 82, 165, 0.1); */
    background-color: rgba(255, 82, 165, 0.1);
    /* background-color: rgba(252, 249, 250, 0.1); */
    color: palevioletred;
    border-color: rgb(255, 82, 165);
    /* fill: #ff52a5; */
    fill: red;
  }
  
  .like-btn-container .like-btn {
    fill: rgba(26, 24, 24, 0.7);
  }
  
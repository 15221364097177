.container_heart{
    position: absolute;
    /* top: 50%;
    left: 50%; */
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    transform: translate(-50%, -50%);
  }
  .container_heart .preloader{
    /* animation: rotate 2.3s cubic-bezier(0.75, 0, 0.5, 1) infinite; */
    position: absolute;
    /* top: 50%;
    left: 50%; */
    top: calc(50% - 12px);
    left: calc(50% - 12px);
  }
  @keyframes rotate {
    50%{
      transform: rotate(360deg);
    }
    100%{
      transform: rotate(720deg);
    }
  }
  .preloader span{
    position: absolute;
    display: block;
    height: 64px;
    width: 64px;
    background: #Fca6AA;
    border: 1px solid #Fca6AA;
    border-radius: 100%;
  }
  .preloader span:nth-child(1){
    transform: translate(-28px, -28px);
    animation: shape_1 2.3s cubic-bezier(0.75, 0, 0.5, 1) infinite;
  }
  @keyframes shape_1 {
    60%{
      transform: scale(0.4);
    }
  }
  .preloader span:nth-child(2){
    transform: translate(28px, -28px);
    animation: shape_2 2.3s cubic-bezier(0.75, 0, 0.5, 1) infinite;
  }
  @keyframes shape_2 {
    40%{
      transform: scale(0.4);
    }
  }
  .preloader span:nth-child(3){
    position: relative;
    border-radius: 0px;
    transform: scale(0.98) rotate(-45deg);
    animation: shape_3 2.3s cubic-bezier(0.75, 0, 0.5, 1) infinite;
  }
  @keyframes shape_3 {
    50%{
      border-radius: 100%;
      transform: scale(0.5) rotate(-45deg);
    }
    100%{
      transform: scale(0.98) rotate(-45deg);
    }
  }
  .shadow{
    position: relative;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    height: 16px;
    width: 64px;
    border-radius: 50%;
    background-color: #d9d9d9;
    border: 1px solid #d9d9d9;
    animation: shadow 2.3s cubic-bezier(0.75, 0, 0.5, 1) infinite;
  }
  @keyframes shadow {
    50%{
      transform: translateX(-50%) scale(0.5);
      border-color: #f2f2f2;
    }
  }
  .my_p{
    position: absolute;
    height: 24px;
    width: 24px;
    top: calc(69% - 12px);
    /* left: calc(45% - 12px); */
    direction: ltr;
  }
  @media (min-width: 1700px) { 
    .my_p{
      /* left: calc(49% - 12px); */
      top: calc(100% - -20px);
    }}